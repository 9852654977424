import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout.en';
import SEO from '../components/Seo';
import Gallery from '../components/Gallery';
import MailchimpForm from '../components/MailchimpForm';
import VideoPlayer from '../components/VideoPlayer';
import Image from '../components/Image';

import launchTrailer from '../images/LaunchTrailerFinal-small2.mp4';

export const query = graphql`
  query {
    poster: file(relativePath: { eq: "Screen-English-v4.png" }) {
      childImageSharp {
        fixed(width: 890, height: 501) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    iconSwitch: file(relativePath: { eq: "IconSwitch.png" }) {
      childImageSharp {
        fixed(width: 150, height: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    iconSteam: file(relativePath: { eq: "IconSteam.png" }) {
      childImageSharp {
        fixed(width: 150, height: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    iconPlaystation: file(relativePath: { eq: "IconPlaystation.png" }) {
      childImageSharp {
        fixed(width: 150, height: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    merchBanner: file(relativePath: { eq: "merchbanner.png" }) {
      childImageSharp {
        fluid(maxWidth: 876) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    threeAttacks: file(relativePath: { eq: "3attacks.png" }) {
      childImageSharp {
        fluid(maxWidth: 598) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    chorusLogo: file(relativePath: { eq: "ChorusLogo.png" }) {
      childImageSharp {
        fixed(width: 150, height: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    tdkLogo: file(relativePath: { eq: "TDKLogo.png" }) {
      childImageSharp {
        fixed(width: 150, height: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

function EnglishPage({ data }) {
  return (
    <Layout>
      <SEO title="De Mambo" lang="en" />
      <main>
        <section className="video-player">
          <VideoPlayer video={launchTrailer} poster={data.poster} />
        </section>

        <section className="content first">
          <p className="tagline">
            De Mambo lets players take control of up to four characters locked
            in infinite Mambo! Help them escape this horrible—and somewhat
            provocative—despair!
          </p>
          <h2>
            <span className="avoidwrap">Out now on:</span>
          </h2>
          <div className="platform-box image-box">
            <div className="platform-logo">
              <Image node={data.iconSwitch} alt="Switch" />
            </div>
            <div className="platform-logo">
              <Image node={data.iconSteam} alt="Steam" />
            </div>
          </div>
          <h2>
            Coming <span className="avoidwrap">soon to:</span>
          </h2>
          <div className="platform-box image-box">
            <div className="platform-logo">
              <Image node={data.iconPlaystation} alt="Playstation" />
            </div>
          </div>
          <p style={{ fontSize: '15px', textAlign: 'center' }}>
            Price: £9.99, €10.99, $12.99
            <br />
            <br />
            Rating: Pegi 3, ESRB E<br />
          </p>
          <br />
          <br />
          <a
            href="https://www.redbubble.com/people/tdkitchen/works/27943029-face-de-mambo-black?p=classNameic-tee&style=classNameic-tee&body_color=purple&print_location=front"
            target="0">
            <Image node={data.merchBanner} alt="Merchandise banner" />
          </a>
          <h2>
            <a href="https://www.redbubble.com/people/tdkitchen/works/27943029-face-de-mambo-black?p=classNameic-tee&style=classNameic-tee&body_color=purple&print_location=front">
              De Mambo Merch now available!
            </a>
          </h2>
        </section>
        <section className="content">
          <h2>
            What is <span className="avoidwrap">De Mambo?</span>
          </h2>
          <p>
            De Mambo is either the best game you've never heard of, or the worst
            game you have heard of. It's a conundrum all right, but to us normal
            folk, who love the arousing feel of a button press and the strangely
            sensual look of cartoon-like characters beating the living crud out
            of each other in a virtual world, De Mambo is a quite simply a
            video-game.
          </p>
          <p>
            De Mambo was designed to be played with one button and a D-pad,
            nothing more, nothing less. There does happen to be a jump button
            and other control options, but it is rumoured that the mystically
            unappealing developers of De Mambo—who also happen to be lazy and
            ugly—favour this one-buttoned control scheme because they lack the
            astute brain power to remember that a typical games controller in
            2016 has more than one button, but alas, I digress.
          </p>
          <div className="image-box">
            <div className="divider">
              <Image
                node={data.threeAttacks}
                alt="Three attacks"
                style={{ margin: '0 auto' }}
              />
            </div>
          </div>
          <p>
            Knock your opponent off the screen... By using your measly one
            button to let loose your three smashing attacks!
          </p>
          <p>
            Break the level however you decide (thanks to some flimsy
            architecture) to make your game marginally different each time you
            play.
          </p>
          <p>
            If you think you'll croak mid-battle under the immense pressure that
            is the brilliance of De Mambo, then The Dangerous Kitchen have your
            back with their patented Loser Rail™. Once you've lost all your
            lives, you are free to carry on playing in this subpar mode designed
            to give you a second chance.
          </p>
        </section>
        <section className="content">
          <h2>Screens</h2>
          <Gallery />
        </section>
        <section className="content">
          <h2>Features!?</h2>
          <ul>
            <li>
              One Button Action – easy to learn, hard to master gameplay
              designed to use one action button and a D-Pad for movement
            </li>
            <li>
              Mambo Multiplayer – roughhouse up to four of your friends/enemies
              in 25 stages of ‘Mambo’
            </li>
            <li>
              Loser Rail – the feature designed to give you a second chance, for
              those of you not good enough to win
            </li>
            <li>
              Flimsy Architecture – breakable environment for reactive gameplay
            </li>
            <li>
              Solo Mode – 70+ Singleplayer stages designed to test your might
            </li>
            <li>
              Survival Mode – Survive the endless waves of Personal-Space
              invaders as they try and breach your personal space
            </li>
            <li>Play with up to four players on one Nintendo Switch™ system</li>
            <li>
              Colour Accessibility – Enhance player visibility, by editing a
              palette of player colours that best suit you
            </li>
          </ul>
        </section>
        <section className="content" id="press">
          <h2>In the press</h2>
          <br />
          <br />
          <ul>
            <li>
              “De Mambo comes together with some artful presentation - its menus
              are a thing to savour and would no doubt do Sakurai himself proud”{' '}
              <b>– Eurogamer</b>
            </li>
            <li>
              “There’s something certifiably wacky and insane about all of this,
              and it has my attention.” <b>– Cliqist</b>
            </li>

            <li>
              “日本製ゲームの種子がイギリスで花開く!!” (“The seed of Japanese
              games blooms in the U.K.!”) <b>– Famitsu</b>
            </li>

            <li>
              “A mixture of the bizarre and the smart, the artistic and the
              lunatic.” <b>- Thumbsticks</b>
            </li>

            <li>
              “分かりやすいゲームデザインなので徐々に夢中になってしまう” (“With
              its easy to pick up game design, De Mambo sucks you in.”){' '}
              <b>– 4Gamer</b>
            </li>
          </ul>
          <br />
          <br />
          <ul>
            <li>
              <a
                href="www.eurogamer.net/articles/2017-06-26-meet-the-developer-making-a-switch-game-in-a-hounslow-costa-coffee"
                target="_blank"
                rel="noopener noreferrer">
                Eurogamer: Meet the developer making a switch game in a hounslow
                costa coffee
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/watch?v=VwT7nN6hXKM&t"
                target="_blank"
                rel="noopener noreferrer">
                Famitsu: Preview【ファミ通ch】『DE MAMBO
                (デマンボ)』をプレイ！　4代目ファミ通ゲーマーズエンジェルのゲー活
                (Japanese)
              </a>
            </li>

            <li>
              <a
                href="https://www.pushsquare.com/news/2015/10/feature_the_ps4_game_thats_being_built_in_a_hotel_lobby"
                target="_blank"
                rel="noopener noreferrer">
                Push Square: Feature: The PS4 Game That’s Being Built in a Hotel
                Lobby
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/watch?v=A7LsAIIkwHw&feature=youtu.be&t=31m59s"
                target="_blank"
                rel="noopener noreferrer">
                Radius Festival Vienna 2015: Night Show
              </a>
            </li>

            <li>
              <a
                href="https://www.thumbsticks.com/de-mambo-an-interview-with-the-premier-inndie/"
                target="_blank"
                rel="noopener noreferrer">
                Thumbsticks: De Mambo: An interview with the Premier Inndie
              </a>
            </li>
          </ul>
        </section>
        <section className="content">
          <h2>Newsletter</h2>
          <p className="centered-text">
            Sign up to The Dangerous Kitchen's mildly entertaining newsletter
            and receive something that'll make junk mail wish it was this bad.
          </p>
          <MailchimpForm />
        </section>
        <section className="content last">
          <h2>Links</h2>
          <div className="image-box">
            <div className="platform-logo">
              <a
                href="https://chorusworldwide.com/en"
                target="_blank"
                rel="noopener noreferrer">
                <Image node={data.chorusLogo} alt="Chorus Worldwide" />
              </a>
            </div>
            <div className="platform-logo">
              <a
                href="http://thedangerouskitchen.co.uk"
                target="_blank"
                rel="noopener noreferrer">
                <Image node={data.tdkLogo} alt="The Dangerous Kitchen" />
              </a>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default EnglishPage;
