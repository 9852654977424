import React from 'react';

const MailchimpForm = () => (
  <div id="mc_embed_signup" className="newsletter-signup-wrapper">
    <form
      action="http://thedangerouskitchen.us10.list-manage.com/subscribe/post?u=90fed4980e40add4164bdb4ac&amp;id=e6d2a3a771"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_blank"
      noValidate>
      <div id="mc_embed_signup_scroll">
        <div className="indicates-required">
          <span className="asterisk">*</span> indicates required
        </div>
        <div className="mc-field-group">
          <label htmlFor="mce-EMAIL">
            Email Address <span className="asterisk">*</span>
          </label>
          <input
            type="email"
            defaultValue=""
            name="EMAIL"
            className="required email"
            id="mce-EMAIL"
          />
        </div>
        <div className="mc-field-group">
          <label htmlFor="mce-NAME">
            Name <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            defaultValue=""
            name="NAME"
            className="required"
            id="mce-NAME"
          />
        </div>
        <div className="mc-field-group">
          <label htmlFor="mce-COUNTRY">
            Country <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            defaultValue=""
            name="COUNTRY"
            className="required"
            id="mce-COUNTRY"
          />
        </div>
        <div className="mc-field-group">
          <label htmlFor="mce-FAVFOOD">
            Favourite Food <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            defaultValue=""
            name="FAVFOOD"
            className="required"
            id="mce-FAVFOOD"
          />
        </div>
        <div id="mce-responses" className="clear">
          <div
            className="response"
            id="mce-error-response"
            style={{ display: 'none' }}
          />
          <div
            className="response"
            id="mce-success-response"
            style={{ display: 'none' }}
          />
        </div>
        {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true">
          <input
            type="text"
            name="b_90fed4980e40add4164bdb4ac_e6d2a3a771"
            tabIndex="-1"
            defaultValue=""
          />
        </div>
        <div className="clear subscribe-button-container">
          <input
            type="submit"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="button"
          />
        </div>
      </div>
    </form>
  </div>
);

export default MailchimpForm;
