import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { I18nProvider } from '@lingui/react';

import Footer from './Footer';
import Header from './Header';

import en from '../locales/en/messages';

const catalogs = { en };

function LayoutEn({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <I18nProvider language="en" locale="en_UK" catalogs={catalogs}>
          <div id="top">
            <Header />
            {children}
            <Footer />
          </div>
        </I18nProvider>
      )}
    />
  );
}

LayoutEn.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutEn;
